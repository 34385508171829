import React, { Component } from 'react'
import envconfig from '../../envconfig/envconfig';

class ContactMain extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tips: '',
      isClick: true, //true代表按钮可被点击,false代表按钮不可被点击
      userName: '',
      companyName: '',
      phone: '',
      email: '',
      title: '',
      content: '',
      fileInput: React.createRef(),
      isFile: '添付ファイル'
    }
  }

  //在页面加载的时候,判断有无localStorage,如果没有就设定一个count=0,过期一小时,放进localStorage
  componentDidMount() {
    let flag = localStorage.getItem('sendCount');
    if (!flag) {
      let sendCount = {
        count: 0,
        time: new Date().getTime()+3600000
        // time: new Date().getTime() + 30000
      }
      localStorage.setItem('sendCount', JSON.stringify(sendCount));
    }
  }

  handleUserNameChange = event => { this.setState({ userName: event.target.value.replace(/(^\s*)/g, "") }) }
  handleCompanyNameChange = event => { this.setState({ companyName: event.target.value.replace(/(^\s*)/g, "") }) }
  handlePhoneChange = event => { this.setState({ phone: event.target.value.replace(/(^\s*)/g, "") }) }
  handleEmailChange = event => { this.setState({ email: event.target.value.replace(/(^\s*)/g, "") }) }
  handleTitleChange = event => { this.setState({ title: event.target.value.replace(/(^\s*)/g, "") }) }
  handleContentChange = event => { this.setState({ content: event.target.value.replace(/(^\s*)/g, "") }) }

  checkFile = () => {
    if (this.state.fileInput.current.files.length === 0) {
      this.setState({ isFile: '添付ファイル' })
    } else {
      this.setState({ isFile: this.state.fileInput.current.files[0].name })
    }
  }

  handleSubmit = () => {
    this.setState({ tips: '' });  // 将tips清空
    this.setState({ isClick: false });

    let flag = localStorage.getItem('sendCount');
    let sendCount = JSON.parse(flag);
    if (sendCount.time < new Date().getTime()) {
      let newSendCount = {
        count: 1,
        time: new Date().getTime()+3600000
        // time: new Date().getTime() + 30000
      }
      localStorage.setItem('sendCount', JSON.stringify(newSendCount));

      const data = new FormData();
      data.append('file', this.state.fileInput.current.files[0]);
      data.append('username', this.state.userName);
      data.append('companyname', this.state.companyName);
      data.append('phone', this.state.phone);
      data.append('email', this.state.email);
      data.append('title', this.state.title);
      data.append('content', this.state.content);
      fetch( envconfig.javaURL + '/contactApi', { method: 'POST', body: data })
        .then(response => {
          response.json().then((data) => {
            if (data.tips !== '伝言成功!') {
              let flag = localStorage.getItem('sendCount');
              let sendCount = JSON.parse(flag);
              sendCount.count--;
              localStorage.setItem('sendCount', JSON.stringify(sendCount));
            }
            this.setState({ tips: data.tips, isClick: true });
            alert(this.state.tips);
          })
        })

    } else {
      if (sendCount.count < 3) {
        sendCount.count++;
        localStorage.setItem('sendCount', JSON.stringify(sendCount));

        const data = new FormData();
        data.append('file', this.state.fileInput.current.files[0]);
        data.append('username', this.state.userName);
        data.append('companyname', this.state.companyName);
        data.append('phone', this.state.phone);
        data.append('email', this.state.email);
        data.append('title', this.state.title);
        data.append('content', this.state.content);
        fetch( envconfig.javaURL + 'contactApi', { method: 'POST', body: data })
          .then(response => {
            response.json().then((data) => {
              if (data.tips !== '伝言成功!') {
                let flag = localStorage.getItem('sendCount');
                let sendCount = JSON.parse(flag);
                sendCount.count--;
                localStorage.setItem('sendCount', JSON.stringify(sendCount));
              }
              this.setState({ tips: data.tips, isClick: true });
              alert(this.state.tips);
            })
          })

      } else {
        this.setState({ tips: '一時間に3つのメッセージしか許されません', isClick: true });
        alert(this.state.tips);
      }
    }
  }

  render() {

    return (
      <div className="contact main layui-container">

        <div className="layui-col-md5 co1">
          <h2>連絡します</h2>

          <div className="layui-row addrow">
            <div className="layui-col-xs2 layui-col-md2">
              <i className="layui-icon layui-inline icon">&#xe68e;</i>
            </div>
            <div className="layui-col-xs10 layui-col-md10">
              <h3>所在地</h3>
              <p>東京都千代田区神田須田町１－２８－４　日宝神田ビル10階</p>
            </div>
          </div>

          <div className="layui-row emailrow">
            <div className="layui-col-xs2 layui-col-md2">
              <i className="layui-icon layui-inline icon">&#xe611;</i>
            </div>
            <div className="layui-col-xs10 layui-col-md10">
              <h3>メール</h3>
              <p>info@sunflower-soft.com</p>
            </div>
          </div>

          <div className="layui-row phonerow">
            <div className="layui-col-xs2 layui-col-md2">
              <i className="layui-icon layui-inline icon">&#xe678;</i>
            </div>
            <div className="layui-col-xs10 layui-col-md10">
              <h3>電話番号</h3>
              <p>03-3525-8872</p>
            </div>
          </div>

          <div className="layui-row phonerow">
            <div className="layui-col-xs2 layui-col-md2">
              <i className="layui-icon layui-inline icon">&#xe66d;</i>
            </div>
            <div className="layui-col-xs10 layui-col-md10">
              <h3>FAX番号</h3>
              <p>03-3525-8713</p>
            </div>
          </div>

        </div>

        <div className="layui-col-md7 co2">
          <input type="text" onChange={e => this.handleUserNameChange(e)} placeholder="氏名を入力してください！" />
          <input type="text" value={this.state.companyName} onChange={e => this.handleCompanyNameChange(e)} placeholder="所属会社を入力してください！" />
          <input type="text" value={this.state.phone} onChange={e => this.handlePhoneChange(e)} placeholder="電話番号を入力してください！" />
          <input type="text" value={this.state.email} onChange={e => this.handleEmailChange(e)} placeholder="メールアドレスを入力してください！" />
          <input type="text" value={this.state.title} onChange={e => this.handleTitleChange(e)} placeholder="件名を入力してください！" className="inptitle" />
          <textarea name="desc" value={this.state.content} onChange={e => this.handleContentChange(e)} placeholder="内容を入力してください！" />
          <input type="file" name='file' id="upFileInput" className="file" ref={this.state.fileInput} onChange={this.checkFile}/>
          <div>
            <label htmlFor="upFileInput" className="filelabel">{this.state.isFile}</label>
            <label htmlFor="upFileInput" className="layui-icon upfilelabel">&#xe67c;</label>
          </div>
          <button onClick={this.handleSubmit} disabled={!this.state.isClick} className="but">すぐに送信します <i className="layui-icon">&#xe609;</i></button>
        </div>
      </div>
    )
  }
}

export default ContactMain