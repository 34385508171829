import React, { Component } from 'react';
import Footer from '../../components/footer/Footer';
import Nav from '../../components/nav/Nav';
import HistoryBanner from '../../components/banner/HistoryBanner'
import HistoryMain from '../../components/main/HistoryMain'

class History extends Component {
  render() {
    return (
      <div>
        <Nav></Nav>
        <HistoryBanner></HistoryBanner>
        <HistoryMain></HistoryMain>
        <Footer></Footer>
      </div>
    )
  }
}

export default History
