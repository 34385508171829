import React, { Component } from 'react'
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'

import index from '../pages/index/Index'
import history from '../pages/history/History'
import cases from '../pages/cases/Cases'
import casesSingle from '../pages/cases/casesSingle/CasesSingle'
import about from '../pages/about/About'
import contact from '../pages/contact/Contact'

class RouterIndex extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path='/' exact render={() => (//下面的:id是为url设置参数 我们可以在casesSingle组件下通过this.props.match.params.id获得
            <Redirect to='/index' />
          )} />
          <Route path='/index' component={index} />
          <Route path='/history' component={history} />
          <Route path='/cases' component={cases} />
          <Route path='/casesSingle/:id' component={casesSingle} />
          <Route path='/about' component={about} />
          <Route path="/contact" component={contact} />
          <Redirect to="/index" />
        </Switch>
      </HashRouter>
    )
  }
}

export default RouterIndex