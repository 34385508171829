import React, { Component } from 'react';
import Nav from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer';
import ContactBanner from '../../components/banner/ContactBanner';
import ContactMain from '../../components/main/ContactMain';

class Contact extends Component {
  render() {
    return (
      <div>
        <Nav></Nav>
        <ContactBanner></ContactBanner>
        <ContactMain></ContactMain>
        <Footer></Footer>
      </div>
    )
  }
}

export default Contact
