import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
import Nav from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer'
import AboutBanner from '../../components/banner/AboutBanner'
import AboutMain from '../../components/main/AboutMain'

class About extends Component {
  render() {
    return (
      <div>
        <Nav></Nav>
        <AboutBanner></AboutBanner>
        <AboutMain></AboutMain>
        <Footer></Footer>
      </div>
    )
  }
}

export default About
