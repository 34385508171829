import React, { Component } from 'react';

class ContactBanner extends Component {

  render() {
    return (
      <div className="banner contact layui-container">
        <div className="title">
          <p>お問い合わせ</p>
          <p className="en">Contact Us</p>
        </div>
      </div>
    )
  }
}

export default ContactBanner