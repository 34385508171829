import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
import Nav from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer';
import CasesBanner from '../../components/banner/CasesBanner';
import CasesMain from '../../components/main/CasesMain'

class Cases extends Component {
  render() {
    return (
      <div>
        <Nav></Nav>
        <CasesBanner></CasesBanner>
        <CasesMain></CasesMain>
        <Footer></Footer>
      </div>
    )
  }
}

export default Cases
