import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MyApi from '../../api/MyApi'
const ReactMarkdown = require('react-markdown')
class CasesSingleMain extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: false,
      message: "",
      title: "",
      contentMD: "",
    }
  }

  initData() {
    let numberArr = window.location.hash.split('/')
    let number = numberArr[numberArr.length - 1]

    let res = {"c_relation":[
      {
        "path":"http://www.shuolei-it.com/",
        "imagePath":"res/static/img/cases1.png",
        "id":"1",
        "title":"無錫碩磊科技有限公司",
        "contentMD":"### &emsp;&emsp;無錫碩磊科技有限公司（無錫）はSUN FLOWER SOFT株式会社に属しており、専門的にソフトウェア分野の技術開発、技術コンサルティング、技術サービス、成果譲渡、アプリの開発と販売、アウトソーシングに従事しております。\r\n### &emsp;&emsp;また、無錫碩磊科技有限公司は創立して以来、ソフトウェア開発に熱心を持ち、スキルの高い人材を募集し、育成して参っております。ソフトウェア開発経験や日本語の基礎が有り、品行が整っております。\r\n### &emsp;&emsp;SUN FLOWER SOFT株式会社（東京）は数年の発展の歴史を持ち、既に比較的に成熟したプロジェクト開発経験、資源とチームを持っております。サービス分野は製造、医療、電力、公共、証券、保険、金融などの様々な業種に及んでおります。",
        "content":"無錫碩磊科技有限公司（無錫）はSUN　FLOWER　SOFT株式会社に属しており、専門的にソフトウェア分野の技術開発、技術コンサルティング、技術サービス、成果譲渡、アプリの開発と販売、アウトソーシングに従事しております。"
      }
    ],
    "message":"请求数据成功",
    "status":true
  }
    res.title = res.c_relation[0].title
    res.contentMD = res.c_relation[0].contentMD
    delete res.c_relation
    this.setState(res)
    // MyApi.queryByIdApi('post','queryByIdApi','c_relation',number+'').then((res) => {
    //   res.title = res.c_relation[0].title
    //   res.contentMD = res.c_relation[0].contentMD
    //   delete res.c_relation
    //   this.setState(res)
    // })
  }

  componentDidMount() {
    this.initData()
  }

  render() {
    return (
      <div className="main-newsdate">
        <div className="layui-container">
          <p className="news"><Link to="/cases">関連会社</Link> > 会社の概要</p>
          <h1>{this.state.title}</h1>
          <ReactMarkdown className="markdown-body" source={this.state.contentMD}/>
        </div>
      </div>
    )
  }
}

export default CasesSingleMain