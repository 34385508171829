import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyApi from '../../api/MyApi'

class Footer extends Component {
  constructor(props){
    super(props)
    this.state = {
      status: false,
      message: "",
      footerData: {
        id: "",
        imagePath: "",
        email: "",
        tel: "",
        time: "",
        address: "",
        copyright: "",
        zipCode: ""
      }
    }
  }

  initData(){
    // let res = {"c_footer":[{"zipCode":"１３５‐００１６","copyright":"Copyright SUN FLOWER SOFT 株式会社 Co. Ltd. All Rights Reserved.","address":"東京都江東区東陽3-23-26 東陽町コーポラス 3F-19号室","imagePath":"/res/static/img/erweima.png","tel":"03-5632-6432","id":"1","time":"9:00-18:00","email":"info@sunflower-soft.com"}],"message":"请求数据成功","status":true}
    let res = {"c_footer":[{"zipCode":"１０１‐００４１","copyright":"ALL RIGHTS RESERVE 2019 BY SHUOLEI GROUP.","address":"東京都千代田区神田須田町１－２８－４　日宝神田ビル10階","imagePath":"/res/static/img/erweima.png","tel":"03-3525-8872","fax":"03-3525-8713","id":"1","time":"9:00-18:00","email":"info@sunflower-soft.com"}],"message":"请求数据成功","status":true}
    res.footerData = res.c_footer[0];
    delete res.c_footer
    this.setState(res)
    // MyApi.queryAllApi('post','queryAllApi','c_footer').then((res) => {
    //   res.footerData = res.c_footer[0];
    //   delete res.c_footer
    //   this.setState(res)
    // })
    
  }

  componentDidMount(){
    this.initData();
  }
  render() {

    return (
      <div className="layui-row">
        <div className="footer">
          <div className="layui-container">
            <div className="layui-row footer-contact">
              <div className="layui-col-sm2 layui-col-lg1 select"><img src={this.state.footerData.imagePath} alt="" /></div>
              <div className="layui-col-sm1 layui-col-lg1 select">&nbsp;</div>
              <div className="layui-col-sm10 layui-col-lg10">
                <div className="layui-row">
                  <div className="layui-col-sm6 layui-col-md6 layui-col-lg6">
                    <p className="select"><span>&emsp;企業情報</span></p><hr />
                    <p><span><Link to="/history" >会社概要</Link></span></p>
                    <p><span><Link to="/cases" >関連会社</Link></span></p>
                    <p><span><Link to="/about" >募集情報</Link></span></p>
                    <p><span><Link to="/contact">お問い合わせ</Link></span></p>
                  </div>
                  <div className="layui-col-sm6 layui-col-md6 layui-col-lg6">
                    <p className="contact-top"><span><i className="layui-icon">&#xe678;</i>&nbsp;&nbsp;{this.state.footerData.tel}</span></p>
                    <p className="contact-top"><span><i className="layui-icon">&#xe66d;</i>&nbsp;&nbsp;{this.state.footerData.fax}</span></p>
                    <p className="contact"><span><strong className="select">〒</strong>&nbsp;&nbsp;{this.state.footerData.zipCode}</span></p>
                    <p className="contact"><span><i className="layui-icon">&#xe68e;</i>&nbsp;&nbsp;{this.state.footerData.address}</span></p>
                    <p className="contact-bottom"><span><i className="layui-icon">&#xe609;</i>&nbsp;&nbsp;{this.state.footerData.email}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="layui-row footer-contact">
              <p className="center"><span>无锡硕磊科技有限公司 版权所有 保留一切权利</span></p>
              <p className="center"><span>{this.state.footerData.copyright}</span></p>
              <p className="center"><a href="https://beian.miit.gov.cn/">苏ICP备19073189号-1</a></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
