import React, { Component } from 'react';
import { Link } from 'react-router-dom';
let $ = require('jquery');
class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: false,
      message: "",
      navData: {
        id: "",
        title: "",
        imagePath: ""
      }
    }
  }

  clickMenu(){
    var btn = $('.nav').find('.nav-list').children('button')
    ,spa = btn.children('span')
    ,ul = $('.nav').find('.nav-list').children('.layui-nav');
    if(!$(spa[0]).hasClass('spa1')){
      spa[0].className = 'spa1';
      spa[1].style.display = 'none';
      spa[2].className = 'spa3';
      $('.nav')[0].style.height = 90 + ul[0].offsetHeight + 'px';
    }else{
      spa[0].className = '';
      spa[1].style.display = 'block';
      spa[2].className = '';
      $('.nav')[0].style.height = 80 + 'px';
    }
  }

  render() {
    return (
      <div className="nav index">
        <div className="layui-container">
          <div className="nav-logo">
            <Link to="/">
              <img src="/res/static/img/logo.png" alt="株式会社" />
              <span>SUN FLOWER SOFT</span><br/><span className="nav-logo-span">株式会社</span>
            </Link>
          </div>
          <div className="nav-list">
            <button onClick={this.clickMenu}>
              <span></span><span></span><span></span>
            </button>
            <ul className="layui-nav" lay-filter="">
              <li className={"layui-nav-item" + (window.location.hash === "#/index" ? " layui-this": "")}><Link to="/" >ホーム</Link></li>
              <li className={"layui-nav-item" + (window.location.hash === "#/history" ? " layui-this": "")}><Link to="/history">会社概要</Link></li>
              <li className={"layui-nav-item" + (window.location.hash === "#/cases" ? " layui-this": "")}><Link to="/cases">関連会社</Link></li>
              <li className={"layui-nav-item" + (window.location.hash === "#/about" ? " layui-this": "")}><Link to="/about">募集情報</Link></li>
              <li className={"layui-nav-item" + (window.location.hash === "#/contact" ? " layui-this": "")}><Link to="/contact">お問い合わせ</Link></li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Nav
