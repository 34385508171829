import React, { Component } from 'react';

class AboutBanner extends Component {

  render() {
    return (
      <div className="banner about layui-container">
        <div className="title">
          <p>募集情報</p>
          <p className="en">Recruitment situation</p>
        </div>
      </div>
    )
  }
}

export default AboutBanner
